//import React from "react";
//import * as RiIcons from "react-icons/ri";

export const SidebarStudentData = [
{
	title: "Home",
	path: "/logindone",

},
{
	title :"Profile",
	path :"./updateprofile",
},
// {
// 	title: "Payment",
// 	path: "/payment",

// },
{
	title: "Payment",
	path: "/getinstallment",

},
{
	title: "Course Details",
    path: "/coursedetails"
},
// {
// 	title :"Resume",
// 	path :'http://localhost:3005'
// }


];
