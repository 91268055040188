import React from 'react';
import NewSidebar from '../../../../Navbar/Navbar';
import Verifyform from '../ApplicationForms/Verify Forms/Verifyform';
//import Viewform from '../ApplicationForms/Viewform/Viewform';


const AdminDashboard = () => {

    return (
        <>
        <NewSidebar />
        <Verifyform />
        </>
    )

}

export default AdminDashboard;