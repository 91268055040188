import React from 'react';
//import NewSidebar from '../../../../Navbar/Navbar';
import AllAdmin from '../ViewAdmin/ViewAdmin';


const SuperAdminDashboard = () => {

    return (
        <>
        <AllAdmin />
        </>
    )

}

export default SuperAdminDashboard;