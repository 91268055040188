import React from "react";
import NewSidebar from "../../../../Navbar/Navbar";


const profile = () => {
    return (
      <>
          <NewSidebar />
          <div>
           <h1>this is your profile page ....</h1>
           <h2>welcome to profile</h2>
      
    </div>
          
      </>
    )
   }
   export default profile;