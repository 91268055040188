//export const BASE_URL = "http://localhost:9190/";
export const BASE_URL = "https://teeny-direction-production.up.railway.app/";
export const ACTIVE = "active";
export const INITIAL = "initial";
export const NOTVERIFIED = 'notVerified';
export const VERIFIED = 'Verified';
export const ISQUERY = 'isquery';
export const DEPT = [
    "Computer",
    "Civil",
    "Mechanical",
    "Electrical",
    "Structural",
    "Electronics",
  ];