import React, {  } from 'react'
// import NewSidebar from '../../Navbar/Navbar'
import "./pending.css"
import Navbarforapp from '../Home/Navbarforapp'
// import "./Application.css"


export default function Pending() {
    
  return (<>
  <Navbarforapp/>
    {/* <NewSidebar /> */}
    <div className='pending'>
        
        <h3> Pending ...</h3>
        <h4>Your Form Is Under Verification </h4>
        <h4>When Form Will verify from Admin Side You Will Able To Acces Dashbord</h4>
        <h1>Thank You...</h1>

        
    </div></>
    
  )
}
